import { Component, Input, Inject } from '@angular/core'; // First, import Input
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { VCTemplateService } from '@gametailors/v-cilitator-angular';
import {environment} from '../../environments/environment';


@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent {
  // Input decorator to make the template variable available to the component
  @Input() template: any;
  @Input() environment: any = environment; // environmental variables

  constructor(
    public dialog: MatDialog,
    private vct: VCTemplateService,
    @Inject(DOCUMENT) private document: Document
    ) { }

  protected deleteTemplate() {
    // Delete the template by using the VCTemplateService
    // First we need to confirm with the user that they want the deletion of the template
    if (confirm("Are you sure you want to delete this template?")) {
      this.vct.deleteRoomTemplate("gofino", this.template.templateId, result => {}, err => {
        // If there is an error, log it to the console
        console.log("Could not delete template:", err);
      });
    }
  }

  protected editTemplate() {
    // open the builder with the template id
    // this.template.templateId
    const url = environment.builderDomain +  `/template?id=` + this.template.templateId;
    this.document.location.href = url;
  }

  protected runTemplate() {
    // open the runner with the template id
    const url = environment.runnerDomain + `/create-room?template=` + this.template.templateId + `&game=gofino`;
    this.document.location.href = url;
  }
  
}
