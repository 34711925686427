<!-- This is the home page of the manager. -->
<!-- Main content -->
<div class="container">
    <br>
    <h2>All</h2>
    <p>{{organisationTemplates.length}} templates found.</p>
    <div class="entry-list">
      <div *ngFor="let template of organisationTemplates">
        <app-entry  [template]="template"></app-entry>
    </div>
      
    </div>
    <br>
    <!--  Input fields for creating a new template 
          In order to make a new template the user must fill in 
          a template name and a template description -->
    <input #AddTemplate type="text" class="form-control" placeholder="Template name" aria-label="Your edited answer"
          aria-describedby="button-addon2">
    <input #TemplateDescription type="text" class="form-control" placeholder="Fill in a nice description of the template" aria-label="Your edited answer"
          aria-describedby="button-addon2">
    <!--  Buttons to create a new template and to refresh the shown templates -->
    <button type="button"  class="btn btn-outline-secondary" id="button-addon2" (click)="createTemplate(AddTemplate.value, TemplateDescription.value)">Add template</button>
    <button type="button"  class="btn btn-outline-primary" id="button-addon2" (click)="getTemplates()">Get template</button>
  </div>