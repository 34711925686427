import { Component } from '@angular/core';
import { AppComponent } from '../app.component';
import { VCAuthService, VCUserService, RegisterAuthUser } from '@gametailors/v-cilitator-angular';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    protected vca: VCAuthService,
    private vcu: VCUserService,
    private app: AppComponent,
    protected loginService: LoginService,
    private router: Router,
  ){}


  get registered(): boolean {
    // Get the registered status from the login service
    return this.loginService.registered
  }

  protected registerUser(username: string) {
    // First we need to create a RegisterAuthUser object
    let newUser: RegisterAuthUser = {
      email: this.vca.currentUser.email,
      info: {
          image: "NicePicture",
          name: username
      }
    }

    // Then we can register the user using the VCUserService
    this.vcu.registerAuthUser(newUser, res => {
      // When the user is registered we update the login status in the login service
      this.loginService.updateLoginStatus()
    }, err => {
      // If the user could not be registered we log the error
      console.log("Could not register user:", err)
    })
    // After registering we need to update the Flow state
    this.app.loginInit();
  }

  get loggedInWithGoogle(): boolean {
    // Get the loggedInWithGoogle status from the VCAuthService
    return this.vca.loggedInWithGoogle;
  }

  protected login() {
    // Login with Google using the VCAuthService
    this.vca.googleLogin();
  }

  protected logout() {
    // Logout using the VCAuthService
    this.vca.logout();
  }

  protected continueToHome() {
    // If this function is called we check if the user is registered,
    // if so we redirect them to the home page
    if (this.registered) {
      this.router.navigate(['home'])
    }
  }
}

