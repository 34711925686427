import { Component, Input } from '@angular/core';
import { VCAuthService, VCFirebaseService, VCUserService, VCOrganizationService, VcFlowService } from '@gametailors/v-cilitator-angular';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent {
  // if this element should be used in the navbar then set this to true
  @Input() public navbar: boolean = false; 

  private _wasInvited: boolean = false;
  
  constructor(
    protected vca: VCAuthService,
    private vcu: VCUserService,
    private vco: VCOrganizationService,
    private vcFlow: VcFlowService,
    protected loginService: LoginService,
    private vcf: VCFirebaseService,
    ){}

    testGetActiveUserOrganization(onComplete: (result?: any) => void, onError?: (err: any) => void): void {
      onComplete({organizationId: "testOrganization"});
  }
  
    test() {
      this.testGetActiveUserOrganization( ({organizationId}) => {
        console.log(organizationId)
      }
      );
    }

  ngOnInit() {
    // update the flow state at the time of initialization
    this.vcFlow.init(state => {});
  }

  get loggedInWithGoogle(): boolean {
    // return true if the user is logged in with Google
    return this.vca.loggedInWithGoogle;
  }

  get wasInvited(): boolean {
    // return true if the user was invited to an organization
    return this._wasInvited;
  }

  set wasInvited(value: boolean) {
    // set the wasInvited status
    this._wasInvited = value;
  }


  public login() {
    // Login with Google using the VCAuthService
    this.vca.googleLogin();
  }

  public logout() {
    // Logout using the VCAuthService and update the login service
    this.vca.logout();
    this.loginService.manuallyLoggedOut()
  }

  public unregisterUser() {
    // Unregister the user using the VCUserService
    // We wrapped the unregister function in a Promise so we can use it with async/await
    const reg = new Promise((resolve, reject) => {
      this.vcu.unregisterAuthUser(res => {
        console.log("User unregistered:", res)
        resolve(res)
      }, err => {
        console.log("Could not unregister user:", err)
        reject(err)
      })
    });
    return reg;
  }

  protected getUserId() {
    // Get the current user id from the VCAuthService
    return this.vca.currentUserUid;
  }

  protected inviteUser(userEmail: string) {
    // First we find the user id belonging to the given email using the VCUserService
    this.vcu.getUserUidByEmail(userEmail, uid => {
      // Then we invite the user to the organization using the VCOrganizationService
      this.vco.inviteUserToOrganization(uid.userUid, res => {
        alert('User invitation sent.')
      }, err => {
        // If the user could not be invited we log the error
        console.log('Failed to send invite', err)
      });
    }, err => {
      // If the user id could not be found we log the error
      console.log('User not found', err)
    });
  }

  protected acceptInvite(orgId: string) {
    // Here we call the cloud function to accept the organization invite
    // Normally we would use the VCOrganizationService to accept the invite but
    // the VCOrganizationService does not handle the accept invite process correctly
    this.vcf.callCloudFunction('acceptOrganizationInvite', { organizationId: orgId }, x => {}, err => {
      // If the invite could not be accepted we log the error
      console.log(`Failed to accept organization invite`, err)
    })
  }

  protected isInvitePending(org: any) {
    // Check if the invite is pending for the current user
    return org.users[this.vca.currentUserUid].invitePending
  }

  protected isInviteAccepted(org: any) {
    // Check if the invite is accepted for the current user
    return org.users[this.vca.currentUserUid].inviteAccepted
  }

  protected showNonActiveOrg(org: any) {
    // Find the organizations for which the user has accepted the invite but is not the active organization
    return org.users[this.vca.currentUserUid].inviteAccepted && this.loginService.activeOrganization != org.id
  }




  protected getOrganization() {
    const org = new Promise((resolve, reject) => {
      this.vco.getActiveUserOrganization(res => {
        console.log('Active user org', res)
        resolve(res)
      }, err => {
        console.log('Failed to get active user orginization', err)
        reject(err)
      });
    });
    return org;
  }

  protected getOrganizationID() {
    this.getOrganization().then((res) => {
      console.log(res)
      return res;
    })
  }

  protected getMyOrganizations() {
    this.vco.getUserOrganizations( res => {
      console.log('User organizations:', res)
    });
  }

  protected switchToSEPCO() {
    this.vco.setActiveUserOrganization("organization-a992d1ec-1116-47ab-a210-dd0ee8137601", res => {
      console.log('Set active user org', res)
      }, err => {
        alert('Failed to set active user orginization');
        console.log('Failed to set active user orginization', err)
      });
  }

  protected getOrganizationsOfUser() {
    this.vco.getUserOrganizations(res => {
      console.log('User orgs', res)
      this.loginService.userOrgs = res;
      this.loginService.updateUsersOfActiveOrg();
    }, err => {
      console.log('Failed to get user orginizations', err)
   
    });
  }

  protected hasPermissions(user: any) {
    const permissions =  {
      createGames: true,
      createRooms: true,
      createTemplates: true,
      deleteGames: true,
      deleteTemplates: true,
      inviteUsers: true,
      kickUsers: true,
      resetRooms: true,
      updateGames: true,
      updateOrganization: true,
      updateTemplates: true,
      updateUserPermissions: true
    }

    for (const right in permissions) {
      if (!user[right]) {
        return false;
      }
    }
    return true;
  }

  protected grantRights(userID: string) {
    this.vco.setUserPermissionsForOrganization(userID,  {
      createGames: true,
      createRooms: true,
      createTemplates: true,
      deleteGames: true,
      deleteTemplates: true,
      inviteUsers: true,
      kickUsers: true,
      resetRooms: true,
      updateGames: true,
      updateOrganization: true,
      updateTemplates: true,
      updateUserPermissions: true
    }, res => {
      console.log('User permissions granted', res)
      alert('User permissions granted.')
    }, err => {
      console.log('Failed to grant user permissions', err)
    });
  }
  protected setOrganisation(orgId: string) {
    this.vco.setActiveUserOrganization(orgId, res => {
      console.log('Set active user org', res)
      this.loginService.activeOrganization = orgId;
    }, err => {
      console.log('Failed to set active user orginization', err)
    });

  }  
  

}
