<!-- Card -->
<div class="card" style="width: 18rem;">
  <div class="card-body">
    <h5 class="card-title">{{template.info.name}}</h5>
    <p class="card-text">{{template.info.description}}</p>
    <div class="btn-group btn-group-sm" role="group" aria-label="Options">
      <!-- Buttons to interact with the template belonging to this card -->
      <!-- Each function is defined in the typescript file -->
      <button type="button" class="btn btn-primary" (click)="runTemplate()">Run</button>
      <button type="button" class="btn btn-primary" (click)="editTemplate()">Edit</button>
      <button type="button" class="btn btn-danger" (click)="deleteTemplate()">Delete</button>
    </div>
  </div>
</div>