import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { VCAuthService, VCOrganizationService, VCUserService } from '@gametailors/v-cilitator-angular';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private subscriptions: Subscription[] = []
  private _userNames: { [uid: string]: string } = {}

  public registered = false
  private _activeOrganization?: string
  public organisationTemplates: any[] = [];
  public userOrgs: any[] = [];
  public usersOfActiveOrg: [string, any][] = [];

  constructor(
    private vca: VCAuthService,
    private vco: VCOrganizationService,
    private vcu: VCUserService,
    private router: Router,
  ) { }

  public set activeOrganization(org: string | undefined) {
    // Set the active organization
    this._activeOrganization = org
    // Update the users of the active organization
    this.updateUsersOfActiveOrg()
  }
  public get activeOrganization() {
    // Return the active organization
    return this._activeOrganization
  }

  public getNameofUser(uid: string) {
    // Return the name of the user with the given uid
    return this._userNames[uid] || "Loading..."
  }

  public updateUsersOfActiveOrg() {
    // Select the id of the active organization
    for (let org of this.userOrgs) {
      if (org.id == this.activeOrganization) {
        // for the active organisations we retrieve the users and store them in the usersOfActiveOrg array
        this.usersOfActiveOrg = Object.entries(org.users);
        // We then update the user names
        this.updateUserNames()
        break;
      }
    }
  }

  protected updateUserNames() {
    // For each user in the usersOfActiveOrg array we get the name of the user
    for (const [id, user] of this.usersOfActiveOrg) {
      this.getNameOfUser(id)
    }
  }

  protected getNameOfUser(uid: string) {
    // Get the name of the user with the given uid using the VCUserService
    this.vcu.getUserInfo(uid, res => {
      // Store the name of the user in the _userNames object
      this._userNames[uid] = res.userInfo.name
    }, err => {});
  }

  public get isFullyLoggedIn() {
    // Return true if the user is logged in with google, is registered and has an active organization
    return this.vca.loggedInWithGoogle && this.registered && (this.activeOrganization != undefined)
  }

  public init() {
    // Subscribe to the onloggedIn event of the VCAuthService
    this.subscriptions.push(
      this.vca.onloggedIn.subscribe(res => {
        // When the user is logged in we update the login status
        this.updateLoginStatus()   
      })
    )
  }

  public updateLoginStatus() {
    // We check the logged in status
    if (this.vca.loggedInWithGoogle) {
      // if the user is logged in with Google we check if the user is fully logged in
      this.onIsLoggedIn()
    } else {
      // if the user is not logged in with Google we call the onNotLoggedIn function
      this.onNotLoggedIn()
    }
  }

  public get wasLoggedIn() {
    // We check whether the user was logged in before
    return sessionStorage.getItem('wasLoggedIn') === "true"
  }

  private set wasLoggedIn(value: boolean) {
    // We store in the session whether the user was logged in before
    sessionStorage.setItem('wasLoggedIn', value + "")
  }

  public manuallyLoggedOut() {
    // We set the wasLoggedIn to false after someone manually logged out
    // This is to prevent the browser to automatically log the user in again
    this.wasLoggedIn = false
  }

  public destroy() {
    // We unsubscribe from all subscriptions
    for (const sub of this.subscriptions) {
      sub.unsubscribe()
    }
  }

  public getNumberOfSubscriptions() {
    // We return the number of subscriptions
    // This function is necessary for testing purposes as we want to test
    // whether all subscriptions are unsubscribed when the destroy function is called
    let count = 0;
    for (const sub of this.subscriptions) {
      // count the number of subscribed subscriptions
      if (!sub.closed) {
        count++;
      }
    }
    return count;
        
  }

  private onNotLoggedIn() {
    // We set the registered to false and the active organization to undefined
    // This is so we can start with a clean slate
    this.registered = false
    this.activeOrganization = undefined
    // We call the onNotFullyLoggedIn function, which will redirect the user to the login page if the user was not logged in before
    this.onNotFullyLoggedIn()
  }

  private onFullyLoggedIn() {
    // Logging in was succesful, so we set the wasLoggedIn to true
    // We also get the templates
    this.wasLoggedIn = true
    this.getTemplates()
  }

  private onNotFullyLoggedIn() {
    // We check if the user was logged in before
    if (!this.wasLoggedIn)
    // If the user was not logged in before we redirect the user to the login page
    // Note that the the root path is redirected automatically to the login page
      this.router.navigate([""])
  }

  private onIsLoggedIn() {
    // These cloud functions are asynchronous, so we need to wait for them to finish before we can return the result
    // We can wait for them by making a promise for each function and then waiting for all promises to resolve
    const orgPromise = new Promise((resolve, reject) => {
      // We get the active organization using the VCOrganizationService
      this.vco.getActiveUserOrganization(({organizationId}) => {
        // We store the active organization in the activeOrganization variable
        this.activeOrganization = organizationId
        resolve(organizationId);
      }, err => {
        // If we could not get the active organization we log the error and set the active organization to undefined
        console.log("Could not get active organization:", err);
        this.activeOrganization = undefined
        reject();
      });
    });

    const regPromise = new Promise((resolve, reject) => {
      // We check if the user is registered using the VCUserService
      this.vcu.isAuthUserRegistered(res => {
        // We store the result in the registered variable, if the isRegistered property is not defined we set this.registered to false
        this.registered = res.isRegistered || false
        resolve(res);
      }, err => {
        // If we could not get the registered status we log the error and set the registered to false
        // This is a little bit strange, but this is because the VCUserService does not return a proper error
        this.registered = err.isRegistered || false
        reject();
      });
    });

    // In the end we wait for both promises to resolve
    Promise.all([orgPromise, regPromise]).then(() => {
      // If both promises resolved we check if the user is registered and has an active organization
      if(this.registered && this.activeOrganization) {
        // If the user is registered and has an active organization we call the onFullyLoggedIn function
        this.onFullyLoggedIn()
      } else {
        // If the user is not registered or does not have an active organization we call the onNotFullyLoggedIn function
        this.onNotFullyLoggedIn()
      }
    });
  }

  // retrieves template names from the database
  public getTemplates(): void {
    // We check if the user is fully logged in
    if (this.isFullyLoggedIn) {
      // If the user is fully logged in we get the templates using the VCOrganizationService
      this.vco.getOrganizationTemplatesForGame("gofino", (res) => {
        // We store the templates in the organisationTemplates array
        this.organisationTemplates = res;
      }, (err) => {
        // If we could not get the templates we log the error
        console.log("getOrganizationTemplatesForGame() failed:", err);
      });
    }
  }
}
