import { Component, Input } from '@angular/core';
import { VCAuthService, VcFlowService } from '@gametailors/v-cilitator-angular';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  @Input() environment: any = environment; // environmental variables

  constructor(
    public vca: VCAuthService,
    private vcFlow: VcFlowService,
    protected loginService: LoginService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Initialize the login service
    this.loginService.init()
  }
  ngOnDestroy() {
    // Destroy the login service
    this.loginService.destroy()
  }

  loginInit(): void {
    // Initialize the Flow state
    this.vcFlow.init((state) => {});  
  }

  protected goToAccountSettings()  {
    // navigate to the account settings page
    this.router.navigate(['account-settings'])
  }

  protected goToHome()  {
    // navigate to the home page if the user is fully logged in according to the login service
    if (this.loginService.isFullyLoggedIn)
      this.router.navigate(['home'])
  }
}
