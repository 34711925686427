import { Component, NgZone, Input } from '@angular/core';
import { VCTemplateService, VcFlowService } from '@gametailors/v-cilitator-angular';
import { getDatabase, ref, onChildAdded, onChildRemoved} from "firebase/database";
import { TEMPLATE } from '../objects/paths';
import { LoginService } from '../login.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public title = 'manager';

  @Input() environment: any = environment; // environmental variables

  constructor(
    private vct: VCTemplateService,
    private vcFlow: VcFlowService,
    private zone: NgZone,
    private loginService: LoginService      
  ) {}

  get organisationTemplates(): any[] {
    // Get the templates from the login service
    return this.loginService.organisationTemplates
  }

  ngOnInit(): void {
    // When the page is first opened we retrieve all templates
    const db = getDatabase();
    const dbRef = ref(db, TEMPLATE);
    // We update the list of templates when a template is added
    onChildAdded(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        this.zone.run(() => {
          this.loginService.getTemplates();
        });
        
      }
    });
    // We update the list of templates when a template is added
    onChildRemoved(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        this.zone.run(() => {
          this.loginService.getTemplates();
        });
      }
    });
  }

  loginInit(): void {
    // Initialize the login flow
    this.vcFlow.init((state) => {});    
  }

  protected getTemplates()  {
    // Get all templates by using the VCTemplateService
    this.loginService.getTemplates();
  }

  protected createTemplate(templateName: string, templateDescription: string): void {
    // Create a template by using the VCTemplateService
    // First we check if the user has entered a name and description
    // These need to be filled in, otherwise the VCTemplateService will throw an error
    if (templateName == "") { alert("Please enter a name for the template"); return; }
    if (templateDescription == "") { alert("Please enter a description for the template"); return; }
    // First we create a new template, this does not yet contain the description
    this.vct.createRoomTemplate("gofino", templateName, result => {
      console.log("Template created:", result);
      // Now we update the template with the description
      this.vct.updateRoomTemplateInfo("gofino", result.templateId, {
        description: templateDescription
      } as any, res => {}, err => {})
    }, err => {});
  }
}
