import { SwRegistrationOptions } from "@angular/service-worker";


export const providers = [
//this is the game name you give when you are on the game-management app's create page
  {provide: 'firebaseGameID', useValue: 'gofino'}, 
  //this is the published game's url (for example with: firebase deploy)
  {provide: 'firebaseGameURL', useValue: 'https://your-game-url.com/'}, 
{
  provide: SwRegistrationOptions,
  useFactory: () => ({enabled: true}),
},
]