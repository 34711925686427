import {MatDialogModule} from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { VCilitatorModule } from '@gametailors/v-cilitator-angular';
import { AppComponent } from './app.component';
import { EntryComponent } from './entry/entry.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
import { providers } from './providers';
import { OrganizationSettingsComponent } from './organization-settings/organization-settings.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

// Standard Angular module setup
@NgModule({
  // We declare all the components we use in our application here
  declarations: [
    AppComponent,
    EntryComponent,
    OrganizationSettingsComponent,
    HomeComponent,
    LoginComponent,
    
  ],
  // We import other modules we use in our application here
  imports: [
    BrowserModule,
    MatDialogModule,
    AppRoutingModule,
    // Here is how we import the firebase module
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)), //ng g environments -> add firebase config to it which you can find in the firebase console
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => {
      const f = getFunctions();
      f.region = 'europe-west1';
      return f;
    }),
    VCilitatorModule,
    ServiceWorkerModule.register('/firebase-messaging-sw.js', {
      enabled: true,
    }),
    AppRoutingModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }
