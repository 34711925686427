<!--Navbar shown at the top of the screen-->
<nav class="navbar navbar-light bg-light">
  <div class="container-fluid">
    <span class="navbar-brand mb-0 h1">Manager</span>
    <button type="button" class="btn btn-link" (click)="goToHome()" [disabled]="!loginService.isFullyLoggedIn">Home</button>
    <button type="button" class="btn btn-link" (click)="goToAccountSettings()" [disabled]="!(vca.loggedInWithGoogle && loginService.registered)">Account settings</button>
    <a class="btn btn-link" href="{{environment.runnerDomain}}" role="button">Runner</a>
    <!-- The organization settings component is initialized with the attribute 'navbar = true' changing what it will display -->
    <app-organization-settings [navbar]="true"></app-organization-settings>
  </div>
</nav>
<router-outlet></router-outlet>