<div *ngIf="navbar">
  <!-- If this component is initialized with the navbar attribute set to true it will only display a log out button -->
  <button class="btn btn-primary m-2" (click)="logout()">Log out</button>
</div>

<div *ngIf="!navbar">
      <button class="btn btn-primary m-2" (click)="test()">test</button>
  <!--  If the component is not initialized with the navbar attribute set to true it will display organization settings 
        These settings allow the user to invite other users to their organization, give full rights to a user, accept invites
        to organizations, show all users in an organization, show all organizations the user is a part of, and allow the user
        to swich their active organization. -->

  <p> Do you want to add users to your organization? You can invite them with their email address here:</p>
  <!-- Invite other users to your active organization -->
  <input #userToInvite type="text" class="form-control" placeholder="User email address" aria-label="Your edited answer"
      aria-describedby="button-addon2">
  <button class="btn btn-outline-secondary" type="button" (click)="inviteUser(userToInvite.value)">
        Invite user</button>
  <br>
  <p> Do you want to accept an invitation to join an organization? You can do so here:</p>
  <!-- Get the list or organizations the user is a part of and has been invited to -->
  <button class="btn btn-outline-primary" type="button" (click)="getOrganizationsOfUser()">
        Get my user organizations</button>
  <br>
  <!-- Retrieve the list of organizations the user is a part of -->
  <h3 *ngIf="loginService.userOrgs.length != 0">Your organizations:</h3>
  <div *ngFor="let org of loginService.userOrgs">
    
    <!--  For each organization we show the name of the organization, if there is an invite pending we show a button with which the user
          can accept the invite, if the organization is not the user's active organization we show a button with which the user 
          can set that organization as their active organization.  -->
    {{org.info.name}}
    <button *ngIf="isInvitePending(org)" class="btn btn-outline-primary" type="button" (click)="acceptInvite(org.id)">
        Accept invitation</button>
    <button *ngIf="showNonActiveOrg(org)" class="btn btn-outline-primary" type="button" (click)="setOrganisation(org.id)">
      Set as my active organization</button>
    
  </div>
  <br>
  <!-- Retrieve the users of the current active organizations -->
  <h4 *ngIf="loginService.usersOfActiveOrg.length != 0">Users of the selected organization:</h4>
  <div *ngFor="let user of loginService.usersOfActiveOrg">
    <!-- For each user in the organization show their name and if they do not have permissions yet you can grant them full organizational rights -->
    {{loginService.getNameofUser(user[0])}}
    <button *ngIf="user[1].inviteAccepted && !hasPermissions(user[1])" class="btn btn-outline-primary" type="button" (click)="grantRights(user[0])">
        Give full rights to user</button>
  </div>
</div>