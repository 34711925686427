<div>

    <br><br><br>
    <!-- If the user is not logged in we show them this part of the page so they can use Google to log into their account -->
    <div *ngIf="!loggedInWithGoogle">
        <div class="card mx-auto w-75">
            <div class="card-body">
                <h5 class="card-title">Login</h5>
                <p class="card-text">It looks like you are not logged in. Please authenticate yourself using a Google account to
                    get access to the manager.</p>
                <button class="btn btn-primary" (click)="vca.googleLogin()">Authenticate with Google</button>
            </div>
        </div>
    </div>

    <!--    If the user has logged in but isn't registered as a user in our system
            we ask them to register a username. This is usually only the case if the user
            logs in for the first time. -->
    <div *ngIf="loggedInWithGoogle && !registered">
            <div class="card mx-auto w-75">
                <div class="card-body">
                    <h5 class="card-title">Login</h5>
                    <p class="card-text">It looks like this is your first time using the Go Fino Manager. Please set a username.</p>

                    <div class="input-group mb-3">
                        <input #createUsername type="text" class="form-control" placeholder="John_Doe" aria-label="text with button addon" aria-describedby="button-addon1">
                        <button class="btn btn-primary" type="button" id="button-addon1" (click)="registerUser(createUsername.value)">Set Username</button>
                    </div>                      
                </div>
            </div>
    </div>

    <!--    If the user has logged in and is registered we show them this part of the page
            to let them know they can continue to the homepage. -->
    <div *ngIf="registered">
        <div class="card mx-auto w-75">
            <div class="card-body">
                <h5 class="card-title">Login</h5>
                <p class="card-text">Welcome to the Go Fino Manager! You may now continue to the homepage to view your meeting templates.</p>
                <button 
                  type="button" class="btn btn-primary" (click)="continueToHome()"
                >Go to Homepage</button>
            </div>
        </div>
    </div>
</div>